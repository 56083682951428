<template>
   <div class="row m-1 px-1">
    <div class="col-xl-12 col-md-12">
      <div class="col-12 px-0 pb-1">
        <div class="card card-psh border m-half h-100">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <p-icon name="bi-collection-fill" size="36px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  Close Inbound Batches
                </h4>
                <p class="card-text mb-0">
                  Batch Maintenance
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <dx-util-validation-group>
              <div class="row">
                <div class="col-12 mb-1">
                  <p class="my-0">
                    To close an inbound, please enter the <b class="text-warning"> ACCOUNT NO </b> and click the button below.
                  </p>
                </div>
              </div>
              <div class="form-row align-items-end">
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
                  <label for="accountNo">Account No</label>
                  <dx-util-number-box id="accountNo" v-model="accountNo" styling-mode="filled">
                    <dx-util-validator>
                      <dx-util-required-rule message="Account No is required" />
                    </dx-util-validator>
                  </dx-util-number-box>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
                  <label for="excludedStoresBox">Excluded Stores</label>
                  <dx-util-text-box id="excludedStoresBox" v-model="excludedStores" />
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
                  <label for="startDateDateBox">Start Date</label>
                  <dx-util-date-box id="startDateDateBox" v-model="startDate"
                    :max="endDate" type="date"
                    date-serialization-format="yyyy-MM-dd hh:mm:ss"
                  />
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
                  <label for="endDateDateBox">End Date</label>
                  <dx-util-date-box id="endDateDateBox" v-model="endDate"
                    :max="endDate" type="date"
                    date-serialization-format="yyyy-MM-dd hh:mm:ss"
                  />
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>&nbsp;</label>
                  <dx-util-button text="Close Inbound Batches" type="default"
                    styling-mode="contained" :element-attr="btnElementAttr"
                    @click="closeInboundBatches"
                  />
                </div>
              </div>
            </dx-util-validation-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maintenanceService from '@/http/requests/common/maintenanceService'
import { Notify } from '@/@robustshell/utils'

export default {
  data() {
    return {
      accountNo: null,
      startDate: null,
      endDate: null,
      excludedStores: '',
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  methods: {
    closeInboundBatches(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        let stores = Array.from(this.excludedStores.split(','))
        if (stores.length === 1 && stores[0] === '') {
          stores = []
        } else {
          stores = stores.map(store => parseInt(store.trim(), 10))
        }
        const payload = {
          accountNo: this.accountNo,
          startDate: this.startDate,
          endDate: this.endDate,
          excludedStores: stores,
        }
        maintenanceService.closeInboundBatches(payload).then(() => {
          Notify.success('Close batch job is created successfully.')
        }).catch(error => {
          Notify.error(`Job creation failed: ${error}`)
        })
      }
    },

  },

}
</script>
